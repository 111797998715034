import * as actionTypes from "app/store/actions/actionTypes";

export const fileUpload = (payload) => ({
  type: actionTypes.FILE_UPLOAD,
  payload,
});
export const fileUploadFailed = (payload) => ({
  type: actionTypes.FILE_UPLOAD_FAILED,
  payload,
});
export const fileUploadSuccess = (payload) => ({
  type: actionTypes.FILE_UPLOAD_SUCCESS,
  payload,
});

export const fileUploadProgress = (payload) => ({
  type: actionTypes.FILE_UPLOAD_PROGRESS,
  payload,
});

export const fileDownloadUrl = (payload, isDialog = false, category, tmpFileGuid = "", originalFileName = "") => ({
  type: actionTypes.FILE_DOWNLOAD_URL,
  payload,
  isDialog,
  category,
  tmpFileGuid,
  originalFileName
});
export const fileDownloadUrlFailed = () => ({
  type: actionTypes.FILE_DOWNLOAD_URL_FAILED,
});
export const fileDownloadUrlSuccess = (payload, documentid) => ({
  type: actionTypes.FILE_DOWNLOAD_URL_SUCCESS,
  payload,
  documentid
});

export const scanningProcessStatus = (id, from, docObject) => ({
  type: actionTypes.SCANNING_PROCESS_STATUS,
  payload: { id, from, docObject },
});

export const scanningProcessStatusSuccess = (payload) => ({
  type: `${actionTypes.SCANNING_PROCESS_STATUS}_SUCCESS`,
  payload,
});

export const scanningProcessStatusFailed = (payload) => ({
  type: `${actionTypes.SCANNING_PROCESS_STATUS}_FAILED`,
  payload,
});

export const createNewDocVersion = (payload) => ({
  type: actionTypes.CREATE_NEW_DOC_VERSION,
  payload,
});

export const createNewDocVersionSuccess = (payload, from) => ({
  type: `${actionTypes.CREATE_NEW_DOC_VERSION}_SUCCESS`,
  payload,
  from,
});

export const createNewDocVersionFailed = (payload) => ({
  type: `${actionTypes.CREATE_NEW_DOC_VERSION}_FAILED`,
  payload,
});

export const createNewDocVersionCancel = (payload, docObject) => ({
  type: `${actionTypes.CREATE_NEW_DOC_VERSION}_CANCEL`,
  payload,
  docObject,
});

export const singleFileProgressing = (payload) => ({
  type: actionTypes.SINGLE_FILE_PROGRESSING,
  payload,
});

export const fileUploadDropZone = (payload) => ({
  type: actionTypes.DROP_ZONE_FILE_UPLOAD,
  payload,
});

export const filePreviewUrl = (payload, isDropzone, isPreviewAudit, versionId, tmpFileGuid = "", originalFileName = "") => ({
  type: actionTypes.FILE_PREVIEW_URL,
  payload,
  isDropzone,
  isPreviewAudit,
  versionId,
  tmpFileGuid,
  originalFileName
});
export const filePreviewUrlFailed = () => ({
  type: `${actionTypes.FILE_PREVIEW_URL}_FAILED`,
});
export const filePreviewUrlSuccess = (payload, fileName, fileType, requestPayload) => ({
  type: `${actionTypes.FILE_PREVIEW_URL}_SUCCESS`,
  payload,
  fileName,
  fileType,
  requestPayload
});

export const filePreviewOpenFromDropzone = () => ({
  type: actionTypes.FILE_PREVIEW_OPEN,
});

export const cancelNewVersionAfterCreate = (payload, searchView, docid) => ({
  type: actionTypes.CANCEL_NEWVERSION_AFTER_CREATE,
  payload,
  searchView,
  docid
});

export const cancelNewVersionAfterCreateSuccess = (payload) => ({
  type: `${actionTypes.CANCEL_NEWVERSION_AFTER_CREATE}_SUCCESS`,
  payload,
});

export const cancelNewVersionAfterCreateFailed = (payload) => ({
  type: `${actionTypes.CANCEL_NEWVERSION_AFTER_CREATE}_FAILED`,
  payload,
});
export const showDownloadLoader = (payload) => ({
  type: `${actionTypes.SHOW_DOWNLOAD_LOADER}`,
  payload
});



