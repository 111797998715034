import { API } from "./baseService";
import { StorageHelper } from "app/core/storageHelper";

export class AuthService {
  static doFetchRoleListView = () => {
    const appSettings = StorageHelper.getAppSettings();
    const basePath = `${appSettings.baseDocumentUrl}/api/v1`;
    return API.get(`${basePath}/study/userviews`);
  };
  static doGetUserStudyList = (data) => {
    const appSettings = StorageHelper.getAppSettings();
    const basePath = `${appSettings.baseTemplateUrl}/api/v1`;
    return API.get(`${basePath}/StudyListForUser?userId=${data.payload}`);
  };
  static doFetchDBCheck = (data) => {
    const appSettings = StorageHelper.getAppSettings();
    const basePath = `${appSettings.baseTemplateUrl}/api/v1`;
    return API.get(`${basePath}/studyinfo/dbcheck`);
  };
  static setMonitorStatus = (data) => {
    const appSettings = StorageHelper.getAppSettings();
    const basePath = `${appSettings.baseTemplateUrl}/api/v1`;
    return API.post(`${basePath}/template/etmftracking`,data);
  };
}
