import * as actionTypes from "app/store/actions/actionTypes";

export const importTemplateFailed = (payload) => ({
  type: actionTypes.TEMPLATE_IMPORT_FAILED,
  payload,
});
export const importTemplateSuccess = (payload) => ({
  type: actionTypes.TEMPLATE_IMPORT_SUCCESS,
  payload,
});

export const fetchTemplateData = () => ({ type: actionTypes.FETCH_TEMPLATE });
export const fetchTemplateFailed = () => ({
  type: actionTypes.FETCH_TEMPLATE_FAILED,
});
export const fetchTemplateSuccess = (payload) => ({
  type: actionTypes.FETCH_TEMPLATE_SUCCESS,
  payload,
});

export const fetchConfigurationData = () => ({
  type: actionTypes.FETCH_CONFIGURATIONS,
});
export const fetchConfigurationFailed = () => ({
  type: actionTypes.FETCH_CONFIGURATIONS_FAILED,
});
export const fetchConfigurationSuccess = (payload) => ({
  type: actionTypes.FETCH_CONFIGURATIONS_SUCCESS,
  payload,
});

export const setActiveAdminTab = (payload) => ({
  type: actionTypes.ACTIVE_ADMIN_TAB,
  payload,
});

export const getTMFStructureData = (payload) => ({
  type: actionTypes.TMF_STRUCTURE_DATA,
  payload,
});
export const getTMFStructureDataFailed = (payload) => ({
  type: `${actionTypes.TMF_STRUCTURE_DATA}_FAILED`,
  payload,
});
export const getTMFStructureDataSuccess = (payload) => ({
  type: `${actionTypes.TMF_STRUCTURE_DATA}_SUCCESS`,
  payload,
});

export const getAllTemplates = (payload) => ({
  type: actionTypes.FETCH_ALL_TEMPLATES,
  payload,
});
export const getAllTemplatesFailed = (payload) => ({
  type: `${actionTypes.FETCH_ALL_TEMPLATES}_FAILED`,
  payload,
});
export const getAllTemplatesSuccess = (payload) => ({
  type: `${actionTypes.FETCH_ALL_TEMPLATES}_SUCCESS`,
  payload,
});

export const selectTemplate = (payload, templatecategory) => ({
  type: actionTypes.SELECT_TEMPLATE,
  payload,
  templatecategory,
});
export const selectTemplateFailed = (payload) => ({
  type: `${actionTypes.SELECT_TEMPLATE}_FAILED`,
  payload,
});
export const selectTemplateSuccess = (payload) => ({
  type: `${actionTypes.SELECT_TEMPLATE}_SUCCESS`,
  payload,
});

export const getLoggedInUser = (payload) => ({
  type: actionTypes.LOGGEDIN_USER,
  payload,
});

export const getStudyInformation = (payload) => ({
  type: actionTypes.STUDY_INFO,
  payload,
});
export const getStudyInformationFailed = (payload) => ({
  type: `${actionTypes.STUDY_INFO}_FAILED`,
  payload,
});
export const getStudyInformationSuccess = (payload) => ({
  type: `${actionTypes.STUDY_INFO}_SUCCESS`,
  payload,
});

export const instantiateTemplate = (payload) => ({
  type: actionTypes.INSTANTIATE_TEMPLATE,
  payload,
});
export const instantiateTemplateFailed = (payload) => ({
  type: `${actionTypes.INSTANTIATE_TEMPLATE}_FAILED`,
  payload,
});
export const instantiateTemplateSuccess = (payload) => ({
  type: `${actionTypes.INSTANTIATE_TEMPLATE}_SUCCESS`,
  payload,
});

export const getViewZoneSectionArtifact = (mode, from, id) => ({
  type: actionTypes.GET_VIEW_ZONE_SEC_ARTIF,
  payload: { from, id, mode },
});
export const getViewZoneSectionArtifactFailed = (payload) => ({
  type: `${actionTypes.GET_VIEW_ZONE_SEC_ARTIF}_FAILED`,
  payload,
});
export const getViewZoneSectionArtifactSuccess = (payload) => ({
  type: `${actionTypes.GET_VIEW_ZONE_SEC_ARTIF}_SUCCESS`,
  payload,
});
export const closeViewZoneSectionArtifact = () => ({
  type: `${actionTypes.GET_VIEW_ZONE_SEC_ARTIF}_CLOSE`,
});

export const exportTemplate = (templateid, templatecategory, templateName) => ({
  type: actionTypes.EXPORT_TEMPLATE,
  payload: { templateid, templatecategory, templateName },
});
export const exportTemplateFailed = (payload) => ({
  type: `${actionTypes.EXPORT_TEMPLATE}_FAILED`,
  payload,
});
export const exportTemplateSuccess = (payload) => ({
  type: `${actionTypes.EXPORT_TEMPLATE}_SUCCESS`,
  payload,
});

export const deleteTemplate = (id) => ({
  type: actionTypes.DELETE_TEMPLATE,
  payload: id,
});
export const deleteTemplateFailed = (payload) => ({
  type: `${actionTypes.DELETE_TEMPLATE}_FAILED`,
  payload,
});
export const deleteTemplateSuccess = (payload) => ({
  type: `${actionTypes.DELETE_TEMPLATE}_SUCCESS`,
  payload,
});

export const getEtmfArchivingData = (payload) => ({
  type: actionTypes.GET_ETMF_ARCHIVING,
  payload,
});
export const getEtmfArchivingDataFailed = (payload) => ({
  type: `${actionTypes.GET_ETMF_ARCHIVING}_FAILED`,
  payload,
});
export const getEtmfArchivingDataSuccess = (payload) => ({
  type: `${actionTypes.GET_ETMF_ARCHIVING}_SUCCESS`,
  payload,
});

export const exportEtmfDocuments = (payload) => ({
  type: actionTypes.EXPORT_ETMF_DOCUMENTS,
  payload,
});
export const exportEtmfDocumentsFailed = (payload) => ({
  type: `${actionTypes.EXPORT_ETMF_DOCUMENTS}_FAILED`,
  payload,
});
export const exportEtmfDocumentsSuccess = (payload) => ({
  type: `${actionTypes.EXPORT_ETMF_DOCUMENTS}_SUCCESS`,
  payload,
});
export const cancelExportEtmfDocuments = (payload) => ({
  type: `${actionTypes.EXPORT_ETMF_DOCUMENTS}_CANCEL`,
  payload,
});
export const watchExportEtmfDocuments = (payload) => ({
  type: `${actionTypes.EXPORT_ETMF_DOCUMENTS}_WATCH`,
  payload,
});

export const closeArchiveErrorMsg = (payload) => ({
  type: `${actionTypes.EXPORT_ETMF_DOCUMENTS}_CLOSE`,
  payload,
});

export const downloadEtmfDocuments = (payload) => ({
  type: actionTypes.DOWNLOAD_ETMF_DOCUMENT,
  payload,
});
export const downloadEtmfDocumentsFailed = (payload) => ({
  type: `${actionTypes.DOWNLOAD_ETMF_DOCUMENT}_FAILED`,
  payload,
});
export const downloadEtmfDocumentsSuccess = (payload) => ({
  type: `${actionTypes.DOWNLOAD_ETMF_DOCUMENT}_SUCCESS`,
  payload,
});

export const maintenanceOnOff = (payload, status, adminStatus) => ({
  type: actionTypes.MAINTENANCE_ON_OFF,
  payload,
  status,
  adminStatus
});
export const maintenanceOnOffFailed = (payload) => ({
  type: `${actionTypes.MAINTENANCE_ON_OFF}_FAILED`,
  payload,
});
export const maintenanceOnOffSuccess = (payload) => ({
  type: `${actionTypes.MAINTENANCE_ON_OFF}_SUCCESS`,
  payload,
});

export const maintenanceStatus = () => ({
  type: actionTypes.MAINTENANCE_STATUS,
});
export const maintenanceStatusFailed = () => ({
  type: `${actionTypes.MAINTENANCE_STATUS}_FAILED`,
});
export const maintenanceStatusSuccess = (payload) => ({
  type: `${actionTypes.MAINTENANCE_STATUS}_SUCCESS`,
  payload,
});

export const editZone = (payload) => ({
  type: `${actionTypes.EDIT_ZONE}`,
  payload,
});

export const editZoneSuccess = (payload) => ({
  type: `${actionTypes.EDIT_ZONE}_SUCCESS`,
  payload,
});

export const editZoneFailed = (payload) => ({
  type: `${actionTypes.EDIT_ZONE}_FAILED`,
  payload,
});

export const editSection = (payload) => ({
  type: `${actionTypes.EDIT_SECTION}`,
  payload,
});

export const editSectionSuccess = (payload) => ({
  type: `${actionTypes.EDIT_SECTION}_SUCCESS`,
  payload,
});

export const editSectionFailed = (payload) => ({
  type: `${actionTypes.EDIT_SECTION}_FAILED`,
  payload,
});

export const addNewZone = (payload) => ({
  type: `${actionTypes.ADD_NEW_ZONE}`,
  payload,
});

export const addNewZoneSuccess = (payload) => ({
  type: `${actionTypes.ADD_NEW_ZONE}_SUCCESS`,
  payload,
});

export const renameTemplate = (payload, fromCategory, isSelect) => ({
  type: actionTypes.RENAME_TEMPLATE,
  payload,
  fromCategory,
  isSelect,
});
export const renameTemplateSuccess = (payload) => ({
  type: `${actionTypes.RENAME_TEMPLATE}_SUCCESS`,
  payload,
});

export const addNewArtifact = (payload) => ({
  type: actionTypes.ADD_NEW_ARTIFACT,
  payload,
});
export const addNewArtifactFailed = (payload) => ({
  type: `${actionTypes.ADD_NEW_ARTIFACT}_FAILED`,
  payload,
});
export const addNewArtifactSuccess = (payload) => ({
  type: `${actionTypes.ADD_NEW_ARTIFACT}_SUCCESS`,
  payload,
});

export const getMileStoneList = (payload) => ({
  type: actionTypes.GET_MILESTONE_LIST,
  payload,
});
export const getMileStoneListFailed = (payload) => ({
  type: `${actionTypes.GET_MILESTONE_LIST}_FAILED`,
  payload,
});
export const getMileStoneListSuccess = (payload) => ({
  type: `${actionTypes.GET_MILESTONE_LIST}_SUCCESS`,
  payload,
});

export const getDatingConventionList = (payload) => ({
  type: actionTypes.GET_DATING_CONVENTION_LIST,
  payload,
});
export const getDatingConventionListFailed = (payload) => ({
  type: `${actionTypes.GET_DATING_CONVENTION_LIST}_FAILED`,
  payload,
});
export const getDatingConventionListSuccess = (payload) => ({
  type: `${actionTypes.GET_DATING_CONVENTION_LIST}_SUCCESS`,
  payload,
});

export const deleteStructure = (payload) => ({
  type: `${actionTypes.DELETE}`,
  payload,
});

export const deleteStructureSuccess = (payload) => ({
  type: `${actionTypes.DELETE}_SUCCESS`,
  payload,
});

export const deleteStructureFailed = (payload) => ({
  type: `${actionTypes.DELETE}_FAILED`,
  payload,
});

export const getTMFStructureChanges = (payload) => ({
  type: actionTypes.GET_TMF_STRUCTURE_CHANGES,
  payload,
});
export const getTMFStructureChangesFailed = (payload) => ({
  type: `${actionTypes.GET_TMF_STRUCTURE_CHANGES}_FAILED`,
  payload,
});
export const getTMFStructureChangesSuccess = (payload) => ({
  type: `${actionTypes.GET_TMF_STRUCTURE_CHANGES}_SUCCESS`,
  payload,
});

export const revertStructureChange = (payload) => ({
  type: `${actionTypes.REVERT_STRUCTURE_CHANGES}`,
  payload,
});

export const revertStructureChangeSuccess = (payload) => ({
  type: `${actionTypes.REVERT_STRUCTURE_CHANGES}_SUCCESS`,
  payload,
});

export const revertStructureChangeFailed = (payload) => ({
  type: `${actionTypes.REVERT_STRUCTURE_CHANGES}_FAILED`,
  payload,
});

export const applyStructureChange = (payload) => ({
  type: `${actionTypes.APPLY_STRUCTURE_CHANGES}`,
  payload,
});

export const applyStructureChangeSuccess = (payload) => ({
  type: `${actionTypes.APPLY_STRUCTURE_CHANGES}_SUCCESS`,
  payload,
});

export const applyStructureChangeFailed = (payload) => ({
  type: `${actionTypes.APPLY_STRUCTURE_CHANGES}_FAILED`,
  payload,
});

export const getEnablingMilestoneStatus = (payload) => ({
  type: actionTypes.GET_ENABLING_MILESTONE_STATUS,
  payload,
});
export const getEnablingMilestoneStatusFailed = (payload) => ({
  type: `${actionTypes.GET_ENABLING_MILESTONE_STATUS}_FAILED`,
  payload,
});
export const getEnablingMilestoneStatusSuccess = (payload) => ({
  type: `${actionTypes.GET_ENABLING_MILESTONE_STATUS}_SUCCESS`,
  payload,
});
export const updateMileStoneStatus = (payload) => ({
  type: actionTypes.UPDATING_ENABLING_MILESTONE_STATUS,
  payload,
});
export const updateMileStoneStatusFailed = (payload) => ({
  type: `${actionTypes.UPDATING_ENABLING_MILESTONE_STATUS}_FAILED`,
  payload,
});
export const updateMileStoneStatusSuccess = (payload) => ({
  type: `${actionTypes.UPDATING_ENABLING_MILESTONE_STATUS}_SUCCESS`,
  payload,
});
export const getFileScreeningStatus = (fileGuid, fileInfo, loadFrom) => ({
  type: actionTypes.TEMPLATE_FILE_SCREENING_STATUS,
  payload: { fileGuid, fileInfo, loadFrom },
});
export const getFileScreeningStatusFailed = (payload) => ({
  type: `${actionTypes.TEMPLATE_FILE_SCREENING_STATUS}_FAILED`,
  payload,
});
export const getFileScreeningStatusSuccess = (payload) => ({
  type: `${actionTypes.TEMPLATE_FILE_SCREENING_STATUS}_SUCCESS`,
  payload,
});
export const getFileScreeningCancel = (payload) => ({
  type: `${actionTypes.TEMPLATE_FILE_SCREENING_STATUS}_CANCEL`,
  payload,
});
export const getFileScreeningCancelBeforeSuccess = (payload) => ({
  type: `${actionTypes.TEMPLATE_FILE_SCREENING_STATUS}_CANCEL_BEFORE_SUCCESS`,
  payload,
});
export const getMileStoneInfoList = (payload) => ({
  type: actionTypes.GET_MILESTONE_INFO_LIST,
  payload,
});
export const getMileStoneInfoListFailed = (payload) => ({
  type: `${actionTypes.GET_MILESTONE_INFO_LIST}_FAILED`,
  payload,
});
export const getMileStoneInfoListSuccess = (payload) => ({
  type: `${actionTypes.GET_MILESTONE_INFO_LIST}_SUCCESS`,
  payload,
});
export const closeMilestoneListView = (payload) => ({
  type: actionTypes.CLOSE_VIEW_MILESTONE,
  payload,
});
export const getMileStone = (payload) => ({
  type: actionTypes.GET_MILESTONE,
  payload,
});
export const getMileStoneFailed = (payload) => ({
  type: `${actionTypes.GET_MILESTONE}_FAILED`,
  payload,
});
export const getMileStoneSuccess = (payload) => ({
  type: `${actionTypes.GET_MILESTONE}_SUCCESS`,
  payload,
});
export const getMilestoneCount = (payload) => ({
  type: actionTypes.GET_MILESTONE_COUNT,
  payload,
});
export const getMileStoneCountFailed = (payload) => ({
  type: `${actionTypes.GET_MILESTONE_COUNT}_FAILED`,
  payload,
});
export const getMileStoneCountSuccess = (payload) => ({
  type: `${actionTypes.GET_MILESTONE_COUNT}_SUCCESS`,
  payload,
});
export const getMilestoneArtifactCount = (payload) => ({
  type: actionTypes.GET_MILESTONE_ARTIFACT_COUNT,
  payload,
});
export const getMileStoneArtifactCountFailed = (payload) => ({
  type: `${actionTypes.GET_MILESTONE_ARTIFACT_COUNT}_FAILED`,
  payload,
});
export const getMileStoneArtifactCountSuccess = (payload) => ({
  type: `${actionTypes.GET_MILESTONE_ARTIFACT_COUNT}_SUCCESS`,
  payload,
});
export const closeMileStoneView = (payload) => ({
  type: actionTypes.CLOSE_SINGLE_MILESTONE_VIEW,
  payload,
});
export const enableFormForAdd = (payload) => ({
  type: actionTypes.ENABLE_FORM_ADD_MILESTONE,
  payload,
});

export const getMileStoneGroup = (payload) => ({
  type: actionTypes.GET_MILESTONE_GROUP,
  payload,
});
export const getMileStoneGroupFailed = (payload) => ({
  type: `${actionTypes.GET_MILESTONE_GROUP}_FAILED`,
  payload,
});
export const getMileStoneGroupSuccess = (payload) => ({
  type: `${actionTypes.GET_MILESTONE_GROUP}_SUCCESS`,
  payload,
});
export const addNewMilestone = (payload) => ({
  type: actionTypes.ADD_NEW_MILESTONE,
  payload,
});
export const addNewMilestoneFailed = (payload) => ({
  type: `${actionTypes.ADD_NEW_MILESTONE}_FAILED`,
  payload,
});
export const setLastMode = (payload) => ({
  type: `${actionTypes.SET_LAST_MODE}`,
  payload,
});
export const setCurrentMode = (payload) => ({
  type: `${actionTypes.SET_CURRENT_MODE}`,
  payload,
});
export const setIsSearchHappend = (payload) => ({
  type: `${actionTypes.IS_SEARCH_HAPPEND}`,
  payload,
});
export const setIsCloseHappend = (payload) => ({
  type: `${actionTypes.IS_CLOSE_HAPPEND}`,
  payload,
});
export const addNewMilestoneSuccess = (payload) => ({
  type: `${actionTypes.ADD_NEW_MILESTONE}_SUCCESS`,
  payload,
});
export const editMilestone = (payload) => ({
  type: actionTypes.EDIT_MILESTONE,
  payload,
});
export const editMilestoneFailed = (payload) => ({
  type: `${actionTypes.EDIT_MILESTONE}_FAILED`,
  payload,
});
export const editMilestoneSuccess = (payload) => ({
  type: `${actionTypes.EDIT_MILESTONE}_SUCCESS`,
  payload,
});
export const changeMilestoneSequence = (payload) => ({
  type: actionTypes.CHANGE_MILESTONE_SEQUENCE,
  payload,
});
export const changeMilestoneSequenceFailed = (payload) => ({
  type: `${actionTypes.CHANGE_MILESTONE_SEQUENCE}_FAILED`,
  payload,
});
export const changeMilestoneSequenceSuccess = (payload) => ({
  type: `${actionTypes.CHANGE_MILESTONE_SEQUENCE}_SUCCESS`,
  payload,
});

export const getMileStoneFilterList = (payload) => ({
  type: actionTypes.GET_MILESTONE_FILTER_LIST,
  payload,
});
export const getMileStoneFilterListFailed = (payload) => ({
  type: `${actionTypes.GET_MILESTONE_FILTER_LIST}_FAILED`,
  payload,
});
export const getMileStoneFilterListSuccess = (payload) => ({
  type: `${actionTypes.GET_MILESTONE_FILTER_LIST}_SUCCESS`,
  payload,
});
export const deleteMilestone = (payload) => ({
  type: actionTypes.DELETE_MILESTONE,
  payload,
});
export const deleteMilestoneFailed = (payload) => ({
  type: `${actionTypes.DELETE_MILESTONE}_FAILED`,
  payload,
});
export const deleteMilestoneSuccess = (payload) => ({
  type: `${actionTypes.DELETE_MILESTONE}_SUCCESS`,
  payload,
});

// Archive audit trail
export const getCompleteAuditTrail = (payload) => ({
  type: actionTypes.GET_COMPLETE_AUDIT_TRAIL,
  payload,
});
export const getCompleteAuditTrailFailed = (payload) => ({
  type: `${actionTypes.GET_COMPLETE_AUDIT_TRAIL}_FAILED`,
  payload,
});
export const getCompleteAuditTrailSuccess = (payload, isFrom) => ({
  type: `${actionTypes.GET_COMPLETE_AUDIT_TRAIL}_SUCCESS`,
  payload,
  isFrom,
});
export const generateCompleteAuditTrail = (payload) => ({
  type: actionTypes.GENERATE_COMPLETE_AUDIT_TRAIL,
  payload,
});
export const generateCompleteAuditTrailFailed = (payload) => ({
  type: `${actionTypes.GENERATE_COMPLETE_AUDIT_TRAIL}_FAILED`,
  payload,
});
export const generateCompleteAuditTrailSuccess = (payload) => ({
  type: `${actionTypes.GENERATE_COMPLETE_AUDIT_TRAIL}_SUCCESS`,
  payload,
});
export const statusCompleteAuditTrail = (payload) => ({
  type: `${actionTypes.GENERATE_COMPLETE_AUDIT_TRAIL}_STATUS`,
  payload,
});
export const cancelCompleteAuditTrail = (payload, isStatus) => ({
  type: `${actionTypes.GENERATE_COMPLETE_AUDIT_TRAIL}_CANCEL`,
  payload,
  isStatus,
});
export const downloadCompleteAuditTrail = (payload) => ({
  type: actionTypes.DOWNLOAD_COMPLETE_AUDIT_TRAIL,
  payload,
});
export const downloadCompleteAuditTrailFailed = (payload, id) => ({
  type: `${actionTypes.DOWNLOAD_COMPLETE_AUDIT_TRAIL}_FAILED`,
  payload,
  id
});
export const downloadCompleteAuditTrailSuccess = (payload) => ({
  type: `${actionTypes.DOWNLOAD_COMPLETE_AUDIT_TRAIL}_SUCCESS`,
  payload,
});
export const cancelCompleteAuditTrailBeforeSuccess = (payload) => ({
  type: `${actionTypes.GENERATE_COMPLETE_AUDIT_TRAIL}_CANCEL_BEFORE_SUCCESS`,
  payload,
});
export const getDropZoneSettings = (payload) => ({
  type: actionTypes.GET_DROP_ZONE_SETTING,
  payload,
});
export const getDropZoneSettingsFailed = (payload) => ({
  type: `${actionTypes.GET_DROP_ZONE_SETTING}_FAILED`,
  payload,
});
export const getDropZoneSettingsSuccess = (payload) => ({
  type: `${actionTypes.GET_DROP_ZONE_SETTING}_SUCCESS`,
  payload,
});
export const getDropZoneFileScreeningStatus = (fileGuid, fileInfo) => ({
  type: actionTypes.GET_DROP_ZONE_FILESCREENING_STATUS,
  payload: { fileGuid, fileInfo },
});
export const getDropZoneFileScreeningStatusFailed = (payload) => ({
  type: `${actionTypes.GET_DROP_ZONE_FILESCREENING_STATUS}_FAILED`,
  payload,
});
export const getDropZoneFileScreeningStatusSuccess = (payload) => ({
  type: `${actionTypes.GET_DROP_ZONE_FILESCREENING_STATUS}_SUCCESS`,
  payload,
});
export const getDropZoneFileScreeningStatusCancel = (payload) => ({
  type: `${actionTypes.GET_DROP_ZONE_FILESCREENING_STATUS}_CANCEL`,
  payload,
});
export const getDropZoneFileScreeningStatusCancelBeforeSuccess = (payload) => ({
  type: `${actionTypes.GET_DROP_ZONE_FILESCREENING_STATUS}_CANCEL_BEFORE_SUCCESS`,
  payload,
});
export const doDropZoneFileScreening = (payload) => ({
  type: `${actionTypes.DO_DROP_ZONE_FILESCREENING_STATUS}`,
  payload,
});
export const resetProgressFileData = (fileInfo, status) => ({
  type: `${actionTypes.RESET_PROGRESS_FILE_DATA}`,
  payload: { fileInfo, status },
});
export const fetchDropZoneDocuments = (payload) => ({
  type: actionTypes.GET_DROP_ZONE_DATA,
  payload,
});
export const fetchDropZoneDocumentsFailed = () => ({
  type: `${actionTypes.GET_DROP_ZONE_DATA}_FAILED`,
});
export const fetchDropZoneDocumentsSuccess = (payload) => ({
  type: `${actionTypes.GET_DROP_ZONE_DATA}_SUCCESS`,
  payload,
});
export const doSaveTMFDropZone = (payload) => ({
  type: actionTypes.DO_SAVE_TMF_DROP_ZONE,
  payload,
});
export const deleteDropZone = (payload) => ({
  type: actionTypes.DELETE_DROPZONE,
  payload,
});
export const setInvalidError = (documentData) => ({
  type: actionTypes.SET_INVALID_FILE_ERROR,
  payload: { documentData },
});
export const removeInvalidDoc = (documentData, index) => ({
  type: actionTypes.REMOVE_INVALID_DOC,
  payload: { documentData, index },
});
export const doSaveTMFDropZoneSuccess = (payload) => ({
  type: `${actionTypes.DO_SAVE_TMF_DROP_ZONE}_SUCCESS`,
  payload,
});
export const removeDeletedDoc = (payload) => ({
  type: actionTypes.REMOVE_DELETE_DOC,
  payload
});
export const activeDropZone = (payload) => ({
  type: actionTypes.ACTIVE_DROP_ZONE,
  payload
});
export const setDropZoneItems = (payload) => ({
  type: actionTypes.SET_DROP_ITEMS,
  payload
});
export const getLockStatusFailed = () => ({
  type: `${actionTypes.GET_LOCK_STATUS}_FAILED`,
});
export const getLockStatusSuccess = (payload) => ({
  type: `${actionTypes.GET_LOCK_STATUS}_SUCCESS`,
  payload,
});
export const getLockStatus = (payload) => ({
  type: actionTypes.GET_LOCK_STATUS,
  payload
});
export const setETMFLock = (payload) => ({
  type: actionTypes.SET_ETMF_LOCK,
  payload
});
export const setETMFLockSuccess = (payload) => ({
  type: `${actionTypes.SET_ETMF_LOCK}_SUCCESS`,
  payload
});
export const setETMFLockFailed = (payload) => ({
  type: `${actionTypes.SET_ETMF_LOCK}_FAILED`,
  payload
});
export const setETMFUnLock = (payload) => ({
  type: actionTypes.SET_ETMF_UNLOCK,
  payload
});
export const setETMFUnLockSuccess = (payload) => ({
  type: `${actionTypes.SET_ETMF_UNLOCK}_SUCCESS`,
  payload
});
export const setETMFUnLockFailed = (payload) => ({
  type: `${actionTypes.SET_ETMF_UNLOCK}_FAILED`,
  payload
});
export const getDocumentTemplateNames = (payload) => ({
  type: actionTypes.GET_DOCU_TEMPLATE_NAMES,
  payload
});
export const getDocumentTemplateNamesSuccess = (payload) => ({
  type: `${actionTypes.GET_DOCU_TEMPLATE_NAMES}_SUCCESS`,
  payload
});
export const getDocumentTemplateNamesFailed = (payload) => ({
  type: `${actionTypes.GET_DOCU_TEMPLATE_NAMES}_FAILED`,
  payload
});
export const updateTemplateNames = (payload) => ({
  type: actionTypes.UPDATE_TEMPLATE_NAMES,
  payload
});
export const updateTemplateNamesSuccess = (payload) => ({
  type: `${actionTypes.UPDATE_TEMPLATE_NAMES}_SUCCESS`,
  payload
});
export const updateTemplateNamesFailed = (payload) => ({
  type: `${actionTypes.UPDATE_TEMPLATE_NAMES}_FAILED`,
  payload
});
export const getAgGridData = (payload) => ({
  type: actionTypes.GET_AGGRID_DATA,
  payload
});
export const getAgGridDataSuccess = (payload) => ({
  type: `${actionTypes.GET_AGGRID_DATA}_SUCCESS`,
  payload
});
export const getAgGridDataFailed = () => ({
  type: `${actionTypes.GET_AGGRID_DATA}_FAILED`
});
export const getSharedDocument = (payload) => ({
  type: actionTypes.GET_SHARED_DOCUMENT,
  payload
});
export const getSharedDocumentSuccess = (payload) => ({
  type: `${actionTypes.GET_SHARED_DOCUMENT}_SUCCESS`,
  payload
});
export const getSharedDocumentFailed = () => ({
  type: `${actionTypes.GET_SHARED_DOCUMENT}_FAILED`,
});
export const saveCustomizedColumState = (payload) => ({
  type: actionTypes.SAVE_CUSTOMIZED_COLSTATE,
  payload
});
export const saveCustomizedColumStateSuccess = (payload) => ({
  type: `${actionTypes.SAVE_CUSTOMIZED_COLSTATE}_SUCCESS`,
  payload
});
export const saveCustomizedColumStateFailed = () => ({
  type: `${actionTypes.SAVE_CUSTOMIZED_COLSTATE}_FAILED`,
});
export const getCustomizedColumState = (payload) => ({
  type: actionTypes.GET_CUSTOMIZED_COLSTATE,
  payload
});
export const getCustomizedColumStateSuccess = (payload) => ({
  type: `${actionTypes.GET_CUSTOMIZED_COLSTATE}_SUCCESS`,
  payload
});
export const getCustomizedColumStateFailed = () => ({
  type: `${actionTypes.GET_CUSTOMIZED_COLSTATE}_FAILED`,
});
export const setAGGridFrom = (payload) => ({
  type: `${actionTypes.SET_AGGRID_FROM}`,
  payload
});
export const handleTemplateCancel = (payload) => ({
  type: `${actionTypes.IS_CANCEL_TEMPLATE}`,
  payload
});
export const getTimeFormat = (payload) => ({
  type: `${actionTypes.GET_TIME_FORMAT}`,
  payload
});
export const getTimeFormatSuccess = (payload) => ({
  type: `${actionTypes.GET_TIME_FORMAT}_SUCCESS`,
  payload
});
export const cloneDocNewVersion = (payload, lastModified, searchView, docid, docverguid) => ({
  type: actionTypes.CLONE_DOC_NEW_VERSION,
  payload,
  lastModified,
  searchView,
  docid,
  docverguid
});
export const cloneDocNewVersionSuccess = (payload) => ({
  type: `${actionTypes.CLONE_DOC_NEW_VERSION}_SUCCESS`,
  payload
});
export const cloneDocNewVersionFailed = (payload) => ({
  type: `${actionTypes.CLONE_DOC_NEW_VERSION}_FAILED`,
  payload
});
export const setActiveDocId = (payload) => ({
  type: `${actionTypes.SET_ACTIVE_DOCID}`,
  payload
});
export const closeDocNewVersion = (payload) => ({
  type: `${actionTypes.CLOSE_DOC_NEW_VERSION}`,
  payload
});
export const comingFromPage = (payload) => ({
  type: `${actionTypes.COMING_FROM_PAGE}`,
  payload
});

export const hideModal = () => ({
  type: `${actionTypes.HIDE_MODAL}`,
});